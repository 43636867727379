import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import { getYoutubeId } from '../../utils/getYoutubeId';
import cardBg from '../../images/video_card_bg.png';
import Img from '../../utils/OptimizedImage';
import Icon from '../../styles/atoms/icons';

const VideoWrapper = ({ youtubeUrl, videoThumbnail }) => {
  const youtubeId = getYoutubeId(youtubeUrl);
  const imageUrl = `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;

  const [play, setPlay] = useState(false);

  const playVideo = useCallback(() => {
    setPlay(true);
  }, []);

  return (
    <>
      <Card>
        <div className="card-image">
          {videoThumbnail?.image ? (
            <Img
              src={videoThumbnail?.image?.url}
              alt={videoThumbnail?.internalName}
              title={videoThumbnail?.image?.title}
            />
          ) : (
            <img src={imageUrl} alt="Video Cover" />
          )}
        </div>
        <div className="overlay">
          <div className="watch-video-btn">
            <span onClick={() => playVideo()} role="button" tabIndex={0}>
              <Icon id="FaPlay" />
            </span>
          </div>
        </div>
      </Card>

      {typeof window !== 'undefined' && (
        <ModalVideo
          channel="youtube"
          isOpen={play}
          videoId={youtubeId}
          onClose={() => setPlay(false)}
        />
      )}
    </>
  );
};

export default VideoWrapper;

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  padding: 8px;
  border-radius: 10px;
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  overflow: hidden;
  @media (max-width: 991px) {
    display: flex;
    width: 92%;
    height: fit-content;
    min-height: 200px;
    margin: 0 auto;
  }

  &::before {
    content: '';
    display: flex;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .card-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    overflow: hidden;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: normal;
    @media (max-width: 767.9px) {
      padding: 30px;
    }
    @media (max-width: 480px) {
      padding: 20px;
    }
    border-radius: 10px;
    overflow: hidden;

    .watch-video-btn {
      span {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 72px;
        padding-left: 4px;
      }

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: var(--primary-color);

      svg {
        font-size: 22px;
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 48px;
  background-image: url(${cardBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 8px 80px rgba(106, 114, 158, 0.2);
  }

  h6 {
    color: #fff;
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 24px;
    white-space: break-spaces;
    color: #fff;
  }

  .btn-outline {
    border-color: #fff;
    color: var(--primary-color);
    background-color: #fff;
  }

  @media (max-width: 575.98px) {
    padding-left: 15px;
    padding-right: 15px;
    h6 {
      font-size: 14px;
    }

    h3 {
      font-size: 18px;
      line-height: 1.5;
    }

    button {
      font-size: 12px;
      padding: 10px 15px;
    }
  }
`;
