import styled, { css } from 'styled-components';

import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

import ListItems from '../../../components/ListItems';

export const HeadingStyles = styled.h2`
  ${font('display', 'md', 700)}
  color: ${props => (props.lightPrint ? colors.base.white : colors.gray[900])};

  ${atMinWidth.sm`
    ${font('display', 'lg', 700)}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', 700)}
  `};

  &.title {
    margin-top: 24px;
    margin-bottom: 72px;

    ${atMinWidth.md`
      margin-bottom: 112px;
    `}

    ${atMinWidth.lg`
      margin-top: 60px;
      margin-bottom: 35px;
    `}
  }
`;

export const Heading = styled(HeadingStyles)``;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${props =>
    props.color || props.lightPrint ? colors.base.white : colors.primary[500]};
`;

export const Subhead = styled.div`
  ${font('text', 'md', '400')}
  color: ${props => (props.lightPrint ? colors.base.white : colors.gray[600])};

  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}

  p {
    margin-bottom: 0;
  }
`;

export const SwitchbackList = styled(ListItems)`
  width: 100%;
  margin-bottom: 0;
`;

export const SwitchStyles = styled.div`
  &:last-child {
    margin-bottom: 0;
  }

  .switch-content {
    display: flex;
    align-items: center;
    height: max-content;

    ${atMinWidth.lg`
      height: auto;
    `}

    .content-text {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      gap: 24px;
      text-align: left;
    }

    .quote-container {
      .quote {
        ${props =>
          props.hasHeading
            ? css`
                ${font('text', 'xs', '400', true)}
                color: ${colors.gray[600]};
                text-indent: -6px;

                ${atMinWidth.sm`
                  ${font('text', 'sm', '400')}
                `}
              `
            : css`
                ${font('display', 'sm', '400')}
                color: ${colors.gray[900]};
                text-indent: -8px;

                ${atMinWidth.sm`
                  ${font('display', 'md', '400')}
                  text-indent: -10px
                `}

                ${atMinWidth.md`
                  text-indent: -14px
                `}

                strong {
                  ${font('display', 'sm', '700')}

                  ${atMinWidth.sm`
                    ${font('display', 'md', '700')}
                  `}
                }
              `}

        margin-bottom: 16px;

        @media screen and (max-width: 575.98px) {
          padding: 0 10px 0 25px;
        }

        @media (max-width: 375px) {
          padding: 0 10px 0 0;
        }
      }

      .company-logo {
        margin-bottom: 16px;
        img {
          height: 15px;
        }
      }

      .person-info {
        color: ${colors.gray[900]};
        font-size: 14.4px;
        margin-bottom: 16px;

        strong {
          font-weight: 700;
        }

        @media (max-width: 575.98px) {
          padding: 0 10px 0 25px;
        }

        @media (max-width: 375px) {
          padding: 0 10px 0 0;
        }
      }
    }

    .stat-container {
      display: flex;

      .stat {
        display: flex;
        flex-direction: column;
        margin-right: 64px;

        h1 {
          color: ${props => props.statColor};
        }

        &-last-of-type {
          margin-right: 0;
        }

        &-label {
          font-size: 14.4px;
          max-width: 110px;
        }
      }
    }
    .text-initial {
      text-transform: initial;
    }
  }

  .image-container {
    .gatsby-image-wrapper {
      display: block;
      margin: 0 auto;
    }

    img {
      display: block;
      max-width: 600px;
      margin: auto;
      object-fit: contain !important;
      object-position: center;

      ${atMinWidth.lg`
        max-width: 585px;
      `}
    }
  }
`;
