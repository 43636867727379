import React, { useEffect } from 'react';
import ListContainerStyles from '../../styles/Components/ListItems/ListContainerStyles';
import Img from '../../utils/OptimizedImage';
import { getTag } from '../../utils/getTag';

const ListItem = ({
  items,
  pricing,
  horizontal,
  active,
  lightNav,
  form,
  spaceBetween,
  hasTags,
  noSubhead,
  className,
  grid,
}) => {
  useEffect(() => {
    if (grid) {
      const links = document.querySelector("a[href='#chat-to-us']");
      if (links) {
        links.classList.add('chat-to-us');
      }
    }
  }, [grid]);

  return (
    <ListContainerStyles
      pricing={pricing}
      horizontal={horizontal}
      active={active}
      lightNav={lightNav}
      form={form}
      spaceBetween={spaceBetween}
      hasTags={hasTags}
      className={className}
      grid={grid}
    >
      {items.map((item, index) => {
        const Heading = item?.headingTag ? getTag(item?.headingTag) : 'p';
        return (
          <div className="list-item" key={item.id ?? `lid_${index}`}>
            {item?.icon?.image && (
              <figure
                className={`list-icon ${item.icon.internalName ===
                  'Blue Minus Icon' && 'gray-icon'}`}
              >
                {item?.icon?.image?.gatsbyImageData ? (
                  <Img
                    image={item.icon.image.gatsbyImageData}
                    alt={`Decorative Icon - ${item.icon.internalName}`}
                  />
                ) : (
                  <Img
                    src={item.icon.image.url}
                    alt={`Decorative Icon - ${item.icon.internalName}`}
                  />
                )}
              </figure>
            )}
            <div className="print-container">
              <div className="list-heading-container">
                {item?.heading && (
                  <Heading
                    className="list-heading"
                    style={{
                      fontFamily: !item?.subhead && 'CircularXX',
                    }}
                  >
                    {item.heading}
                  </Heading>
                )}
                {item?.tagLabel && (
                  <div className="list-tag-label">{item.tagLabel}</div>
                )}
              </div>
              {item?.subhead && item?.heading?.toLowerCase() !== 'call us' && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.subhead }}
                  className={`list-subhead ${noSubhead ? 'd-none' : ''}${
                    item?.icon?.internalName === 'Green Check Icon'
                      ? 'text-color'
                      : ''
                  }`}
                />
              )}
              {item?.subhead && item?.heading?.toLowerCase() === 'call us' && (
                <div className="list-subhead">
                  <a href={`tel:${item.subhead.replace(/<[^>]+>/gi, '')}`}>
                    {item.subhead.replace(/<[^>]+>/gi, '')}
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </ListContainerStyles>
  );
};

export default ListItem;
