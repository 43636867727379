import { useLocation } from '@reach/router';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Section from '../../molecules/Section/Section';
import { BackgroundImage } from '../../molecules/Section/Section.styles';
import {
  Heading,
  Kicker,
  Subhead,
  SwitchStyles,
  SwitchbackList,
} from '../../styles/Components/SwitchComponent/SwitchStyles';
import { colors } from '../../styles/atoms/colors';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';
import getExtensionFromUrl from '../../utils/getExtensionFromUrl';
import { getTag } from '../../utils/getTag';
import LottieWrapper from './LottieWrapper';
import VideoWrapper from './VideoWrapper';
import { HeroNeedsVideo } from '../Hero/components/HeroNeedsVideo';

const SwitchComponent = ({ component }) => {
  const {
    backgroundColor,
    backgroundImage,
    cta,
    featuredContent,
    featuredVideo,
    heading,
    headingKicker,
    headingKickerTag,
    headingTag,
    kickerColor,
    lightPrint,
    listItems,
    reverse,
    scrollId,
    statBlock,
    subhead,
    test,
    videoLink,
  } = component;

  const headingTagFallback = component?.title ? 'h3' : 'h2';
  const isAboutPage = useLocation()?.pathname.includes('about');
  const isAutonomy = component?.headingKicker?.includes('Autonomy');
  const grid = component?.viewMode?.includes('Grid') || statBlock?.length > 0;
  const content = useMemo(
    () =>
      featuredContent?.image &&
      getExtensionFromUrl(featuredContent?.image?.url) !== 'json' ? (
        <Img
          image={featuredContent?.image?.gatsbyImageData}
          src={featuredContent?.image?.url}
          alt={featuredContent?.internalName}
        />
      ) : (
        <LottieWrapper path={featuredContent?.image?.url} />
      ),
    [featuredContent]
  );
  return (
    <Section
      id={scrollId || component?.id}
      bgColor={backgroundColor?.hex || colors.base.white}
      className={clsx(
        isAutonomy && 'autonomy',
        isAboutPage &&
          headingKicker?.toLowerCase().includes('careers') &&
          'about-career'
      )}
    >
      {backgroundImage?.image && (
        <BackgroundImage image={backgroundImage?.image?.gatsbyImageData} />
      )}
      <SwitchStyles
        reverse={reverse}
        testimonial={test?.quote}
        statColor={statBlock[0]?.statColor?.hex}
        hasHeading={heading}
      >
        {component?.title && (
          <Row>
            <div className="text-center w-100">
              <Heading className="title" lightPrint={lightPrint}>
                {component?.title}
              </Heading>
            </div>
          </Row>
        )}
        <Row className="switch-content">
          <Col
            lg={reverse ? { order: 2, span: 6 } : { order: 1, span: 6 }}
            className="mb-5 mb-lg-0 content-text"
          >
            {headingKicker && (
              <Kicker
                as={getTag(headingKickerTag)}
                color={kickerColor}
                lightPrint={lightPrint}
              >
                {headingKicker}
              </Kicker>
            )}
            {heading && (
              <Heading
                as={headingTag ? getTag(headingTag) : headingTagFallback}
                lightPrint={lightPrint}
              >
                {heading}
              </Heading>
            )}
            {subhead && (
              <Subhead
                lightPrint={lightPrint}
                dangerouslySetInnerHTML={{ __html: subhead }}
              />
            )}
            {listItems.length > 0 && (
              <SwitchbackList
                items={listItems}
                className="switch-list"
                grid={grid}
              />
            )}
            {test?.quote && (
              <div className="quote-container">
                <div
                  dangerouslySetInnerHTML={{ __html: test.quote }}
                  className="quote"
                />
                <p className="person-info">
                  <strong>
                    {test?.person?.firstName} {test?.person?.lastName},{' '}
                  </strong>
                  {test?.person?.role}, {test?.person?.company?.name}
                </p>
              </div>
            )}
            {statBlock?.length > 0 && (
              <div className="stat-container">
                {statBlock.map(stat => (
                  <div className="stat" key={stat.id}>
                    <h1>{stat?.statNumber}</h1>
                    <div className="stat-label">{stat?.statLabel}</div>
                  </div>
                ))}
              </div>
            )}
            {cta?.label && cta?.variant && (
              <CallToAction
                value={cta?.label}
                variant={cta?.variant}
                link={cta?.url}
                openInNewTab={cta?.openInNewTab}
              />
            )}
          </Col>
          <Col
            lg={reverse ? { order: 1, span: 6 } : { order: 2, span: 6 }}
            className={`image-container ${reverse ? 'reverse-image' : ''}`}
            as={featuredContent?.link ? Link : undefined}
            to={featuredContent?.link ? featuredContent?.link : undefined}
          >
            {featuredVideo ? (
              <VideoWrapper
                videoThumbnail={featuredVideo?.videoThumbnail}
                youtubeUrl={featuredVideo?.youtubeUrl}
              />
            ) : (
              <HeroNeedsVideo component={{ videoLink: videoLink }}>
                {content}
              </HeroNeedsVideo>
            )}
          </Col>
        </Row>
      </SwitchStyles>
    </Section>
  );
};

export default SwitchComponent;
